import React from 'react';
import Helmet from 'react-helmet';
import Section from '@latitude/section';
import { Link } from '@latitude/link';
import { BUTTON_STYLE, ALIGN, COLOR } from '@latitude/core/utils/constants';
import { Heading4 } from '@latitude/heading';
import { AccordionSidebar } from '@latitude/accordion';
import { StickyNavigation } from '@latitude/sticky-navigation';
import { Box } from '@latitude/box';
import Hero from '../../components/Hero/Hero';
import CircledCharacter from '../../components/CircledCharacter/CircledCharacter';
import Layout from '../../components/layout';
import ResponsiveImage from '../../components/ResponsiveImage';
import './genoapay.scss';
import allDataJson from '../../data/pages/genoapay.json';
import faqsGenoapay from './_faqs';
import { PageContext } from '@/context/PageContext';

class GenoapayPage extends React.Component {
  static contextType = PageContext;
  constructor(props) {
    super(props);
    this.state = { selectedListFaq: 0 };
    this.selectItem = this.selectItem.bind(this);
    this.genoapayPageFaq = allDataJson.faq;
  }

  state = { isNavSticky: false };

  // Handle the change of the "Sticky Navigation" state
  handleStickyNavStateChange = isNavSticky =>
    this.setState(prevState => {
      if (prevState.isNavSticky !== isNavSticky)
        return { ...prevState, isNavSticky };
      return prevState;
    });

  selectItem(index) {
    this.setState({ selectedListFaq: index });
  }

  render() {
    return (
      <Layout>
        <main className="navigation-spacer genoapay-page">
          <Helmet>
            <link
              rel="canonical"
              href="https://www.gemfinance.co.nz/genoapay"
            />
            <title>Genoapay | Gem by Latitude</title>
            <meta
              name="description"
              content="Genoapay lets you get what you want today and spread the payments out over 10 weekly instalments. T&amp;Cs apply."
            />
          </Helmet>

          <Hero
            backgroundImage={require('../../images/Genoapay_background.png')}
            heading="Meet another great way to pay."
            intro=""
            className="page-banner-lfs--bg"
            introIcons={
              <ResponsiveImage
                src={require('../../images/Genoapay_logo_white.png')}
              />
            }
            cta={
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <Link
                    trackId="genoapay-hero-apply-now"
                    trackEventData={{
                      category: 'cta',
                      action: 'application-link',
                      location: 'Hero'
                    }}
                    href="https://app.genoapay.com/#sign-up"
                    button={BUTTON_STYLE.SECONDARY}
                    css={`
                      background: #006ac6 !important;
                      &:hover {
                        background-color: #0046aa !important;
                        border-color: #006aff !important;
                      }
                    `}
                  >
                    Apply Now
                  </Link>
                </div>
              </div>
            }
            image=""
          />

          <StickyNavigation
            items={allDataJson.nav}
            isSticky={this.state.isNavSticky}
            onStickyNavStateChange={this.handleStickyNavStateChange}
            offsetElem="[data-sticky-navigation-offset]"
            {...this.context?.updatedBody?.inPageNavData?.[0]}
          />

          <Section id="what-is-genoapay" className="bg-white">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-8  text-center">
                <h3 className="text-center">What is Genoapay?</h3>
                <div className="col-sm-12 text-center p-3">
                  <p>
                    Genoapay lets you get what you want today and spread the
                    payments out over 10 weekly instalments.
                  </p>
                  <p>
                    Sign-up is free and Genoapay can be used for purchases of
                    $20 - $1,000* at hundreds of participating businesses.
                  </p>
                </div>
              </div>
            </div>
          </Section>

          <Section className="bg-lightest">
            <div className="row justify-content-center" id="how-it-works">
              <div className="col-12 col-sm-8  text-center">
                <h3>How Genoapay Works</h3>
                <div className="row bg-white align-items-center p-3 m-3">
                  <div className="col-2 col-sm-2 text-center">
                    <CircledCharacter>1</CircledCharacter>
                  </div>
                  <div className="col-10 col-sm-10 text-center">
                    <p>
                      Sign up for free - it only takes a few minutes and
                      approval is in real time.
                    </p>
                  </div>
                </div>

                <div className="row bg-white align-items-center p-3 m-3">
                  <div className="col-2 col-sm-2 text-center">
                    <CircledCharacter>2</CircledCharacter>
                  </div>
                  <div className="col-10 col-sm-10 text-center">
                    <p>
                      Choose Genoapay when checking out in store or online at
                      participating stores.
                    </p>
                  </div>
                </div>

                <div className="row bg-white align-items-center p-3 m-3">
                  <div className="col-2 col-sm-2 text-center">
                    <CircledCharacter>3</CircledCharacter>
                  </div>
                  <div className="col-10 col-sm-10 text-center">
                    <p>
                      Your account will be activated once you&#39;ve confirmed
                      your payment details by entering your credit or debit
                      card.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center text-invert mt-5">
              <div className="col-sm-12 col-md-6">
                <div className="row align-items-center">
                  <div className="col-md-6 col-sm-12">
                    <Link
                      trackId="genoapay-find-out-more"
                      trackEventData={{
                        category: 'button-link',
                        action: 'external-link',
                        location: 'How Genoapay Works'
                      }}
                      href="https://www.genoapay.com/how-it-works/"
                      button={BUTTON_STYLE.SECONDARY}
                      css={`
                        background: #0046aa !important;
                        border-color: #0046aa !important;
                      `}
                    >
                      Find out more
                    </Link>
                  </div>
                  <div className="col-md-6 col-sm-12s">
                    <Link
                      trackId="genoapay-sign-up"
                      trackEventData={{
                        category: 'cta',
                        action: 'application-link',
                        location: 'How Genoapay Works'
                      }}
                      href="https://app.genoapay.com/#sign-up"
                      button={BUTTON_STYLE.SECONDARY_INVERSE}
                      css={`
                        color: #0046aa !important;
                        background: transparent !important;
                        border-color: #0046aa !important;
                        &:hover {
                          color: #ffffff !important;
                          background: #0046aa !important;
                          border-color: #0046aa !important;
                        }
                      `}
                    >
                      Sign-up
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Section>

          <Box id="faq">
            <Box.Section>
              <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
                Frequently asked questions
              </Heading4>
              <AccordionSidebar data={faqsGenoapay.genoapay} />
            </Box.Section>
          </Box>

          <Section id="important-information">
            <div className="row">
              <div className="col-lg-9 mx-auto">
                <p className="text--small text-center">
                  <sup>*</sup>
                  Subject to credit limit provided on application. Genoapay is
                  provided by Latitude Innovation Holdings Limited.
                </p>
              </div>
            </div>
          </Section>
        </main>
      </Layout>
    );
  }
}

export default GenoapayPage;
